import React, {useCallback, useContext, useEffect, useState} from 'react';
import useTranslation from "next-translate/useTranslation";
import styles from "./styles.module.scss";
import {Button, ButtonGroup} from "@shopify/polaris";
import Portal from "../../../../helpers/hocs/Portal";
import Link from "next/link";
import {ACCEPT_COOKIES_NAMESPACE} from "../../../../utils/i18nNameSpaces";
import {shouldShowCookieBanner} from "../../../../helpers/cookie/shouldShowCookieBanner";
import cn from "classnames";
import {setShouldShowCookieBanner} from "../../../../helpers/cookie/setShouldShowCookieBanner";
import {setUsableCookies} from "../../../../helpers/cookie/setUsableCookies";
import {cookieInit} from "../../../../helpers/cookie/cookieInit";
import {COOKIE_CONTEXT} from "../../../providers/CookieProvider";
import {usableCookieAdapter} from "../../../../helpers/cookie/usableCookieAdapter";
import Close from "../../components/Icons/Common/Close";

export const closeBanner = () => {
  setShouldShowCookieBanner(false);
}

export const COOKIE_POLICY_LINK = "cookie-policy";
export const COOKIE_BANNER_ID = "cookie-banner";
const Index = () => {
  const {t} = useTranslation(ACCEPT_COOKIES_NAMESPACE);
  const {hinderingCookie, setIsCustomizerOpen, isBannerOpen, setIsBannerOpen, browserCookieDisabled, setBrowserCookieDisabled} = useContext(COOKIE_CONTEXT);

  const handleAllowAll = useCallback(() => {
    setUsableCookies(true);
    closeBanner();
    setIsBannerOpen(false)
  }, []);

  const handleRejectALl = useCallback(() => {
    setUsableCookies(false);
    closeBanner();
    setIsBannerOpen(false)
  }, []);

  useEffect(() => {
    cookieInit();
    setIsBannerOpen(shouldShowCookieBanner() || isBannerOpen);
  }, [isBannerOpen, setIsBannerOpen]);

  return (
    <Portal selector={COOKIE_BANNER_ID}>
      <div className={cn(styles["banner"], {[styles["banner--active"]]: isBannerOpen})}>
        <div className={styles["container"]}>
          {browserCookieDisabled
          ?
            <>
              <div className={cn(styles["text-container"], styles["text-container--nocookie"])}>
                <p className={styles["certain-cookie-text"]}>{t("banner.noCookie")}</p>
                <div className={styles["close-wrapper"]} onClick={() => setIsBannerOpen(false)}>
                  <Close/>
                </div>
              </div>
            </>
            :
            <>
              <div className={styles["text-container"]}>
                {!hinderingCookie || hinderingCookie.length === 0 ?
                  <p>
                    {t("banner.text")}&nbsp;
                    <span className={styles["link-wrapper"]}>
                  <Link href={COOKIE_POLICY_LINK}>
                    <a>{t("banner.cookiePolicy")}</a>
                  </Link>
                </span>
                  </p>
                  :
                  <p className={styles["certain-cookie-text"]}>
                    {t("banner.text-cookie._1")}&nbsp;
                    "{hinderingCookie.map((el, i) => <span
                    key={i}>{t(`cookies.${el}.name`)}{i === hinderingCookie.length - 2 && ", "}</span>)}"&nbsp;
                    {t("banner.text-cookie._2")}
                    <span className={styles["link-wrapper"]}>
                   &nbsp;<span className={styles["enable-text"]} onClick={() => {
                      setUsableCookies(usableCookieAdapter(hinderingCookie));
                      closeBanner();
                      setIsBannerOpen(false);
                    }}>{t("banner.enable")}</span>
                </span>
                  </p>
                }
              </div>
              <div className={styles["actions-wrapper"]}>
                <div className={styles["links"]}>
                  <div className={styles["link-wrapper"]}>
                    <button onClick={() => setIsCustomizerOpen(true)}>{t("banner.cookieCustomize")}</button>
                  </div>
                </div>
                <ButtonGroup fullWidth>
                  <Button onClick={handleRejectALl}>{t("banner.basicBtn")}</Button>
                  <Button primary={true} onClick={handleAllowAll}>{t("banner.primaryBtn")}</Button>
                </ButtonGroup>
              </div>
            </>
          }
        </div>
      </div>
    </Portal>
  );
};

export default Index;