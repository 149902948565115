import Head from "next/head";
import dynamic from "next/dynamic";
const Header = dynamic(() => import("../Header"))
const Footer = dynamic(() => import("../Footer"))
const Layout = ({children}) => {
  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width,initial-scale=1'/>
      </Head>
      <Header/><main>{children}</main><Footer/>
    </>
  )
}

export default Layout;