import {COOKIE_ANALYTICS, COOKIE_FUNCTIONALITY, COOKIE_STORAGE_NAME, COOKIE_TARGETING} from "../../utils/constants";
import {isLocalStorageAvailable} from "../isLocalStorageAvailable";

export const setUsableCookies = (cookiesObj = false) => {
  if (!isLocalStorageAvailable()) {
    return false;
  }
  let currentCookies = JSON.parse(localStorage?.getItem(COOKIE_STORAGE_NAME));

  if (cookiesObj === false) {

    currentCookies = {
      [COOKIE_ANALYTICS]: false,
      [COOKIE_FUNCTIONALITY]: false,
      [COOKIE_TARGETING]: false
    }
  } else if (cookiesObj === true) {

    currentCookies = {
      [COOKIE_ANALYTICS]: true,
      [COOKIE_FUNCTIONALITY]: true,
      [COOKIE_TARGETING]: true
    }
  } else {
    currentCookies = {...currentCookies, ...cookiesObj};
  }

  localStorage.setItem(COOKIE_STORAGE_NAME, JSON.stringify(currentCookies));
}