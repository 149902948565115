const RF_NAMESPACE = "reactFlow";
const SEL_NAMESPACE = "selectors";
const HOME_NAMESPACE = "home";
const RC_NAMESPACE = "recharge";
const COMMON_NAMESPACE = "common";
const TRAINING_NAMESPACE = "training";
const ABOUT_NAMESPACE = "about";
const HIRE_NAMESPACE = "hire";
const PRIVACY_NAMESPACE = "privacy";
const TERM_OF_USE_NAMESPACE = "terms-of-use";
const TERM_OF_USE_DISCORD_NAMESPACE = "terms-of-use/discord";
const TERM_OF_USE_SHOPIFY_NAMESPACE = "terms-of-use/shopify";
const ACCEPT_COOKIES_NAMESPACE = "accept-cookie";
const COOKIE_POLICY_NAMESPACE = "cookie-policy";
const CONTACT_US_NAMESPACE = "contact-us";
const CUSTOMER_SUPPORT_NAMESPACE = "customer-support";
const APPS_NAMESPACE = "apps";
const SUGGESTION_NAMESPACE = "suggestion";
const DISCORD_TELEGRAM_PRIVACY_NAMESPACE = "discord-telegram-privacy";
const DISCORD_TELEGRAM_NAMESPACE = "discord-telegram";
const AFFILIATE_TERMS_NAMESPACE = "affiliate-terms";
// const DISCORD_EMAIL_PRIVACY_NAMESPACE = "discord-email-privacy";
// const DISCORD_EMAIL_NAMESPACE = "discord-email";

module.exports = {
  RF_NAMESPACE,
  SEL_NAMESPACE,
  HOME_NAMESPACE,
  RC_NAMESPACE,
  COMMON_NAMESPACE,
  TRAINING_NAMESPACE,
  ABOUT_NAMESPACE,
  HIRE_NAMESPACE,
  PRIVACY_NAMESPACE,
  TERM_OF_USE_NAMESPACE,
  TERM_OF_USE_DISCORD_NAMESPACE,
  TERM_OF_USE_SHOPIFY_NAMESPACE,
  ACCEPT_COOKIES_NAMESPACE,
  COOKIE_POLICY_NAMESPACE,
  APPS_NAMESPACE,
  SUGGESTION_NAMESPACE,
  CUSTOMER_SUPPORT_NAMESPACE,
  CONTACT_US_NAMESPACE,
  DISCORD_TELEGRAM_PRIVACY_NAMESPACE,
  DISCORD_TELEGRAM_NAMESPACE,
  AFFILIATE_TERMS_NAMESPACE,
  // DISCORD_EMAIL_NAMESPACE,
  // DISCORD_EMAIL_PRIVACY_NAMESPACE,
};
