import React, {useContext, useEffect, useState} from 'react';
import Head from "next/head";
import {checkUsableCookie} from "../../../helpers/cookie/checkUsableCookie";
import {COOKIE_ANALYTICS} from "../../../utils/constants";
import {COOKIE_CONTEXT} from "../CookieProvider";

const Index = ({children}) => {
  const [canShowGtm, setCanShowGtm] = useState(false);
  const {
    isBannerOpen,
    setIsBannerOpen} = useContext(COOKIE_CONTEXT)

  useEffect(() => {
    setCanShowGtm(checkUsableCookie(COOKIE_ANALYTICS));
  }, [
    isBannerOpen,
    setIsBannerOpen])

  return (
    <>
      <Head>
        {
          canShowGtm &&
          <script async dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-TQQZ773');`
          }}
          />
        }
      </Head>
      {children}
    </>
  );
};

export default Index;