export const AVAILABLE_LANGUAGES = {
  en: {
    name: "English",
    code: "en",
  },
  uk: {
    name: "Українська",
    code: "uk",
  },
};

export const LANGUAGES_LIST = [
  {
    name: "English",
    code: "en",
  },
  {
    name: "Українська",
    code: "uk",
  },
];

export const BOTS = {
  telegram: {
    client_id: process.env.NEXT_PUBLIC_TELEGRAM_BOT_CLIENT_ID,
    client_secret: process.env.NEXT_PUBLIC_TELEGRAM_BOT_CLIENT_SECRET,
    redirect_uri: `${process.env.NEXT_PUBLIC_URL}/discord-telegram`,
  },
  email: {
    client_id: process.env.NEXT_PUBLIC_EMAIL_BOT_CLIENT_ID,
    client_secret: process.env.NEXT_PUBLIC_EMAIL_BOT_CLIENT_SECRET,
    redirect_uri: `${process.env.NEXT_PUBLIC_URL}/discord-email`,
  },
};

export const RF = "React Flow";
export const SEL = "Selectors";
export const RC = "ReSell";
export const DS_TG = "Telegram";
export const DS_EMAIL = "Email";

export const RF_SITE_LINK = "/react-flow";
export const SEL_SITE_LINK = "/selectors";
export const RC_SITE_LINK = "/resell";
export const DISCORD_TELEGRAM_LINK = "/discord-telegram";
export const DISCORD_EMAIL_LINK = "/discord-email";

export const APP_STORE_LINK = `https://apps.shopify.com/partners/devit-software`;
export const THEME_STORE_LINK = "https://themes.shopify.com/";

/* Discord */
export const DISCORD_INVITE_LINK = "https://discord.gg/sg72ENbAz4";
export const DISCORD_TG_BOT_LINK = "https://telegram.devit.software";
export const DISCORD_TG_TOP_GG_LINK = "https://top.gg/bot/1123949801613049866";
export const DISCORD_TG_APP_DIR_LINK =
  "https://discord.com/application-directory/1123949801613049866";
export const DISCORD_TG_CONNECT_VIDEO =
  "https://www.youtube.com/watch?v=-Ll_-XG9i9w&ab_channel=DevIT";
export const DISCORD_AUTH_LINK = `https://discord.com/oauth2/authorize?response_type=code&client_id=${process.env.NEXT_PUBLIC_TELEGRAM_BOT_CLIENT_ID}&scope=guilds+identify&redirect_uri=${process.env.NEXT_PUBLIC_URL}/discord/telegram`;
export const DISCORD_EMAIL_BOT_LINK = process.env.NEXT_PUBLIC_EMAIL_BOT_API_URL;
export const DISCORD_EMAIL_AUTH_LINK = `https://discord.com/oauth2/authorize?response_type=code&client_id=${process.env.NEXT_PUBLIC_EMAIL_BOT_CLIENT_ID}&scope=guilds+identify&redirect_uri=${process.env.NEXT_PUBLIC_URL}/discord/email`;
export const DEVIT_HELP_CENTER = "https://help.devit.software/en/";

export const ACADEMY_LINK = "https://devit.academy/";
export const JOBS_LINK = "https://jobs.devit.group/";

export const RF_TESTIMONIALS_LINK =
  "https://apps.shopify.com/react-flow/reviews?ratings%5B%5D=5&sort_by=newest";
export const RF_TESTIMONIALS_CACHE_KEY = "react-flow-testimonials";

export const SEL_TESTIMONIALS_LINK =
  "https://apps.shopify.com/selectors/reviews?ratings%5B%5D=5&sort_by=newest";
export const SEL_TESTIMONIALS_CACHE_KEY = "selectors-testimonials";

export const RC_TESTIMONIALS_LINK =
  "https://apps.shopify.com/resell/reviews?ratings%5B%5D=5&sort_by=newest";
export const RC_TESTIMONIALS_CACHE_KEY = "resell-testimonials";

export const REACT_FLOW_LINK = "https://apps.shopify.com/react-flow";
export const SELECTORS_LINK = "https://apps.shopify.com/selectors";
export const RESELL_LINK = "https://apps.shopify.com/resell";

export const SEL_APP_LINK = "https://selectors.devit.software/";
export const RF_APP_LINK = "https://reactflow.devit.software";
export const RC_APP_LINK = "https://resell.devit.software/";

export const TESTIMONIAL_CACHE_TIME = 24 * 1000 * 60 * 60;
export const VIDEO_HASH_TAG = "video";

export const IDENTITY_HEADER = "email-from-identity-header";

export const TRAINING_IDENTITY = "training";
export const CONTACT_US_IDENTITY = "training";
export const HIRE_IDENTITY = "hire";

export const SHOULD_COOKIE_BANNER = "should_cookie_banner";
export const COOKIE_STORAGE_NAME = "usable_cookies";
export const COOKIE_FUNCTIONALITY = "cookie_functionality";
export const COOKIE_TARGETING = "cookie_targeting";
export const COOKIE_ANALYTICS = "cookie_analytics";
export const COOKIE_STRICTLY = "cookie_strictly";

export const SHOULD_SUGGESTION = "suggestion";

export const SITE_URL = "https://devit.software";

export const HR_SOFTWARE = "hr@devit.software";
export const HR_GROUP = "hr@devit.group";
export const SUPPORT_GROUP = "support@devit.software";

export const SOFTWARE_PHONE = {
  pretty: "+38 (091) 481-02-16",
  code: "+380914810216",
};
export const GROUP_PHONE = {
  pretty: "+1 (929) 237-1255",
  code: "+19292371255",
};

export const NAV_LINKS = {
  applications: [
    { href: SEL_SITE_LINK, text: SEL },
    { href: RF_SITE_LINK, text: RF },
    { href: RC_SITE_LINK, text: RC },
  ],
  discord: [
    { href: DISCORD_TELEGRAM_LINK, text: DS_TG },
    // { href: DISCORD_EMAIL_LINK, text: DS_EMAIL },
  ],
};
