import {useEffect, useState} from "react"
import {createPortal} from "react-dom"

const Portal = ({children, selector = "side-bar-portal"}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    return () => setMounted(false);
  }, [])

  return mounted
    ? createPortal(children,
      document.querySelector("#" + selector))
    : null;
}

export default Portal;