export const usableCookieAdapter = (cookieType) => {
  if (Array.isArray(cookieType)) {
    return cookieType.reduce(
      (accumulator, currentValue) => {
         accumulator[currentValue] = true
        return accumulator;
      },
      {}
    );
  }
  return {
    [cookieType]: true
  }
}