import {COOKIE_STORAGE_NAME} from "../../utils/constants";

export const checkUsableCookie = (cookie) => {
  if (typeof window === "undefined") {
    return false;
  }

  let cookies;
  try {
    cookies = localStorage?.getItem(COOKIE_STORAGE_NAME)
  } catch (e) {
    return false
  }

  if (Array.isArray(cookie)) {
    const parse = JSON.parse(cookies);
    if (!parse) {
      return false;
    }
    for (let i = 0; i < cookie.length; i++) {
      if (!parse[cookie[i]]) {
        return false;
      }
    }
    return true;
  }

  if (!cookies) {
    return false;
  }

  return JSON.parse(cookies)[cookie];
};