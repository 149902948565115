import React, {createContext, useContext, useState} from 'react';
import AcceptCookieBanner, {closeBanner} from "../../modals/AcceptCookieBanner";
import AcceptCookie from "../../modals/AcceptCookie";
import Toast from "../../modals/Toast";
import {COOKIE_CONTEXT} from "../../../providers/CookieProvider";
import Portal from "../../../../helpers/hocs/Portal";
import useTranslation from "next-translate/useTranslation";
import {ACCEPT_COOKIES_NAMESPACE} from "../../../../utils/i18nNameSpaces";
import {setUsableCookies} from "../../../../helpers/cookie/setUsableCookies";

import {usableCookieAdapter} from "../../../../helpers/cookie/usableCookieAdapter";

const Index = () => {
  const {
    shouldShowCookieToast,
    hinderingCookie,
    setIsBannerOpen,
    setShouldShowCookieToast,
    browserCookieDisabled
  } = useContext(COOKIE_CONTEXT);
  const {t} = useTranslation(ACCEPT_COOKIES_NAMESPACE);
  const cookies = hinderingCookie.map(el => t(`cookies.${el}.name`));
  return (
    <>
      <AcceptCookieBanner/>
      <AcceptCookie/>
      <Portal
        selector={"alert"}
        children={
          <Toast
            shouldShowAlert={shouldShowCookieToast}
            message={browserCookieDisabled ? t("toastNoCookie") : t("toastText", {cookie: `"${cookies.join(", ")}"`})}
            schema={"warning"}
            position={"bottom"}
            noAction={browserCookieDisabled}
            action={
              browserCookieDisabled
                ? {}
                :
                {
                  content: t(`toastAction`),
                  onAction:
                    () => {
                      setUsableCookies(usableCookieAdapter(hinderingCookie));
                      closeBanner();
                      setIsBannerOpen(false);
                      setShouldShowCookieToast(false);
                    }
                }
            }
          />
        }
      />
    </>
  );
};

export default Index;