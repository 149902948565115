import React, {createContext, useCallback, useEffect, useState} from 'react';
import {isLocalStorageAvailable} from "../../../helpers/isLocalStorageAvailable";

export const COOKIE_CONTEXT = createContext(null);
const Index = ({children}) => {
  const [isCustomizerOpen, setIsCustomizerOpen] = useState(false);
  const [isBannerOpen, setIsBannerOpen] = useState(false);
  const [hinderingCookie, setHinderingCookie] = useState([]);
  const [shouldShowCookieToast, setShouldShowCookieToast] = useState(null);
  const [intervalID, setIntervalID] = useState(null);
  const [browserCookieDisabled, setBrowserCookieDisabled] = useState(false);
  const openBanner = (cookieType) => {
    setIsBannerOpen(true);
    setHinderingCookie(state => {
      if (Array.isArray(cookieType)) {
        return [...cookieType]
      }

      if (state.includes(cookieType)) {
        return state;
      }

      return [...state, cookieType]
    });
  }

  const openCookieToast = useCallback((cookieType) => {
    setShouldShowCookieToast(true);
    clearTimeout(intervalID)
    const interval = setInterval(() => {
      setShouldShowCookieToast(false)
      clearInterval(interval)
    }, 5000)
    setIntervalID(interval);
  }, [shouldShowCookieToast, intervalID])

  const closeCookieToast = () => {
    setShouldShowCookieToast(false)
    clearTimeout(intervalID)
  }

  useEffect(() => {
    if (!isLocalStorageAvailable()) {
      setBrowserCookieDisabled(true);
      setIsBannerOpen(true);
    }
  }, [])

  return (
    <COOKIE_CONTEXT.Provider
      value={
        {
          isCustomizerOpen,
          setIsCustomizerOpen,
          isBannerOpen,
          setIsBannerOpen,
          hinderingCookie,
          setHinderingCookie,
          shouldShowCookieToast,
          setShouldShowCookieToast,
          openCookieToast,
          openBanner,
          closeCookieToast,
          browserCookieDisabled,
          setBrowserCookieDisabled
        }
      }>
      {children}
    </COOKIE_CONTEXT.Provider>
  );
};

export default Index;