import React, {useContext} from 'react';
import cn from "classnames";
import styles from "./styles.module.scss";
import Alert from "../../components/Icons/Common/Alert";
import PropTypes from "prop-types";
import {isEmpty} from "lodash";
import Close from "../../components/Icons/Common/Close";
import {COOKIE_CONTEXT} from "../../../providers/CookieProvider";

const Index = ({shouldShowAlert, message, schema, action, position, closeAction, noAction}) => {
  const {closeCookieToast, browserCookieDisabled} = useContext(COOKIE_CONTEXT);

  return (
    <div
      className={cn(styles["alert"], {[styles["alert--active"]]: shouldShowAlert}, styles[schema], styles[position])}>
      <div className={styles["message"]}>
        <div className={styles["icon-wrapper"]}>
          <Alert/>
        </div>
        <p>{message}</p>
      </div>
      <div className={styles["actions"]}>
        {!isEmpty(action) &&
          <div className={styles["action"]} onClick={action.onAction}>
            {action.content}
          </div>}
        <div className={styles["close"]} onClick={() => {
          closeCookieToast && closeCookieToast();
          closeAction && closeAction()
        }}>
          <Close/>
        </div>
      </div>
    </div>
  );
};

Index.defaultprops = {
  message: "",
  type: "default",
  schema: "error",
  position: "top",
  noAction:"false",
  action: {},
}

Index.proptypes = {
  message: PropTypes.string,
  schema: PropTypes.oneOf(["error", "warning"]),
  position: PropTypes.oneOf(["top", "bottom"]),
  action: PropTypes.shape({
    content: PropTypes.string,
    onAction: PropTypes.func,
  }),
  noAction: PropTypes.bool
}

export default Index;