import React, {createContext, useCallback, useState} from 'react';

export const TESTIMONIALS_CONTEXT = createContext(null);

const Index = ({children}) => {
  const [testimonials, setTestimonials] = useState({});

  const addTestimonials = useCallback((name, testimonials) => {
    setTestimonials(state => ({...state, [name]: testimonials}))
  }, [])


  return (
    <TESTIMONIALS_CONTEXT.Provider
      value={{
        testimonials,
        addTestimonials
      }}>
      {children}
    </TESTIMONIALS_CONTEXT.Provider>
  );
};

export default Index;