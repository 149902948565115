import React, {useCallback, useEffect, useState} from 'react';
import {useRouter} from "next/router";
import useTranslation from "next-translate/useTranslation";
import {Modal} from "@shopify/polaris";
import {SUGGESTION_NAMESPACE} from "../../../../utils/i18nNameSpaces";
import {AVAILABLE_LANGUAGES, SHOULD_SUGGESTION} from "../../../../utils/constants";
import commonEN from "/locales/en/suggestion.json";
import commonUK from '/locales/uk/suggestion.json';
import {isLocalStorageAvailable} from "../../../../helpers/isLocalStorageAvailable";
import {has} from "lodash";

const T = {
  en: commonEN,
  uk: commonUK
}

const Index = () => {
  const {lang} = useTranslation(SUGGESTION_NAMESPACE)
  const router = useRouter();
  const [active, setActive] = useState(false);
  const [detectedLang, setDetectedLang] = useState(lang);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  useEffect(() => {
    if (!isLocalStorageAvailable()) {
      return;
    }

    if (localStorage?.getItem(SHOULD_SUGGESTION) === "false") {
      return;
    }

    const navLang = navigator.language;

    if (router.locale === navLang) {
      return;
    }

    if (!has(AVAILABLE_LANGUAGES, navLang)) {
      return;
    }

    setDetectedLang(navLang);
    setActive(true);
    localStorage.setItem(SHOULD_SUGGESTION, "false")
  }, [])

  return (
    <Modal
      open={active}
      onClose={toggleActive}
      title={T[detectedLang]?.header}
      primaryAction={{
        content: T[detectedLang]?.confirm,
        onAction: () => {
          router.push(router.asPath, router.asPath, {locale: detectedLang});
          setActive(false);
        },
      }}
      secondaryActions={[
        {
          content: T[detectedLang]?.cancel[lang],
          onAction: () => setActive(false),
        },
      ]}
    >
      <Modal.Section>
        <p>{T[detectedLang]?.preferLang}</p>
      </Modal.Section>
    </Modal>
  );
};

export default Index;