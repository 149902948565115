import React from 'react';

const Index = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M9.414 8L15.707 1.707C15.8945 1.51949 15.9999 1.26518 15.9999 1C15.9999 0.734825 15.8945 0.48051 15.707 0.293002C15.5195 0.105494 15.2652 0.000152588 15 0.000152588C14.7348 0.000152588 14.4805 0.105494 14.293 0.293002L8 6.586L1.707 0.293002C1.61416 0.200157 1.50393 0.126509 1.38263 0.0762618C1.26132 0.0260146 1.1313 0.000152588 1 0.000152588C0.8687 0.000152588 0.738683 0.0260146 0.617376 0.0762618C0.496069 0.126509 0.385846 0.200157 0.293002 0.293002C0.105494 0.48051 0.000152588 0.734825 0.000152588 1C0.000152588 1.26518 0.105494 1.51949 0.293002 1.707L6.586 8L0.293002 14.293C0.105494 14.4805 0.000152588 14.7348 0.000152588 15C0.000152588 15.2652 0.105494 15.5195 0.293002 15.707C0.48051 15.8945 0.734825 15.9999 1 15.9999C1.26518 15.9999 1.51949 15.8945 1.707 15.707L8 9.414L14.293 15.707C14.3857 15.8002 14.4958 15.8741 14.6171 15.9246C14.7385 15.9751 14.8686 16.001 15 16.001C15.1314 16.001 15.2615 15.9751 15.3829 15.9246C15.5042 15.8741 15.6143 15.8002 15.707 15.707C15.7999 15.6142 15.8737 15.504 15.924 15.3827C15.9743 15.2614 16.0002 15.1313 16.0002 15C16.0002 14.8687 15.9743 14.7386 15.924 14.6173C15.8737 14.496 15.7999 14.3858 15.707 14.293L9.414 8Z" fill="#5C5F62"/>
      </svg>
    </>
  );
};

export default Index;