import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import Portal from "../../../../helpers/hocs/Portal";
import styles from "./styles.module.scss";
import useTranslation from "next-translate/useTranslation";
import Close from "../../components/Icons/Common/Close";
import {closeBanner, COOKIE_POLICY_LINK} from "../AcceptCookieBanner";
import Link from "next/link";
import {Button, ButtonGroup, Collapsible} from "@shopify/polaris";
import {ACCEPT_COOKIES_NAMESPACE} from "../../../../utils/i18nNameSpaces";
import cn from "classnames";
import logo from "/public/common/logo.svg";
import Image from "next/image";
import {COOKIE_ANALYTICS, COOKIE_FUNCTIONALITY, COOKIE_STRICTLY, COOKIE_TARGETING} from "../../../../utils/constants";
import {setUsableCookies} from "../../../../helpers/cookie/setUsableCookies";
import {getUsableCookies} from "../../../../helpers/cookie/getUsableCookies";
import {COOKIE_CONTEXT} from "../../../providers/CookieProvider";
import {useRouter} from "next/router";

export const COOKIE_CUSTOMIZE_ID = "cookie-customize";

const Index = () => {
  const {t} = useTranslation(ACCEPT_COOKIES_NAMESPACE);
  const router = useRouter();
  const {isCustomizerOpen, setIsCustomizerOpen, setIsBannerOpen} = useContext(COOKIE_CONTEXT);
  const [cookies, setCookies] = useState({
    [COOKIE_ANALYTICS]: false,
    [COOKIE_FUNCTIONALITY]: false,
    [COOKIE_TARGETING]: false,
  });

  const [collapsible, setCollapsible] = useState({
    [COOKIE_ANALYTICS]: false,
    [COOKIE_FUNCTIONALITY]: false,
    [COOKIE_TARGETING]: false,
    [COOKIE_STRICTLY]: false,
  })

  const cookiePolicyNavigate = useCallback(() => {
    setIsCustomizerOpen(false);
    router.push("/" + COOKIE_POLICY_LINK);
  }, [])

  useEffect(() => {
    if (isCustomizerOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
    setCookies(getUsableCookies());
  }, [isCustomizerOpen, setIsCustomizerOpen, setIsBannerOpen])

  const toggleItem = useMemo(() => (name) => {
    return (
      <div className={styles["item"]}>
        <div className={styles["activator-wrapper"]}>
          <button className={cn(styles["activator"], {[styles["activator--active"]]: collapsible[name]})}
                  onClick={() => setCollapsible(state => ({...state, [name]: !state[name]}))}>
            <div className={styles["plus-wrapper"]}>
              <Close/>
            </div>
            <div className={styles["toggle-text-wrapper"]}>
              <p>{t(`cookies.${name}.name`)}</p>
            </div>
          </button>
          {name !== COOKIE_STRICTLY ?
            <button
              className={cn(styles["cookie-button"], {[styles["cookie-button--active"]]: cookies[name]})}
              onClick={() => setCookies(state => ({...state, [name]: !state[name]}))}>
              <div></div>
            </button>
            :
            <p className={styles["always-active"]}>{t("alwaysActive")}</p>
          }
        </div>
        <Collapsible id={"basic-collapsible"} open={collapsible[name]}>
          <p className={styles["cookie-desc"]}>{t(`cookies.${name}.desc`)}</p>
        </Collapsible>
      </div>
    )
  }, [cookies, collapsible])

  const handleConfirmCustomCookie = useCallback(() => {
    setUsableCookies(cookies);
    setIsCustomizerOpen(false);
    closeBanner();
    setIsBannerOpen(false);
  }, [cookies])

  const handleAllowALl = useCallback(() => {
    setUsableCookies(true);
    setIsCustomizerOpen(false);
    closeBanner();
    setIsBannerOpen(false);
  }, [cookies])

  const handleRejectALl = useCallback(() => {
    setUsableCookies(false);
    setIsCustomizerOpen(false);
    closeBanner();
    setIsBannerOpen(false);
  }, [cookies])

  return (
    <Portal selector={COOKIE_CUSTOMIZE_ID}>
      <div className={cn(styles["overlay"], {[styles["overlay--active"]]: isCustomizerOpen})} onClick={() => setIsCustomizerOpen(false)}/>
      <div className={cn(styles["main"], {[styles["main--active"]]: isCustomizerOpen})}>
        <div className={styles["container"]}>
          <div className={styles["header-wrapper"]}>
            <div className={styles["logo-wrapper"]}>
              <Image src={logo} layout="responsive" quality={100}/>
            </div>
            <div className={styles["close-wrapper"]} onClick={() => setIsCustomizerOpen(false)}>
              <Close/>
            </div>
          </div>
          <div className={styles["content"]}>
            <h3 className={styles["header"]}>{t("content.header")}</h3>
            <p>{t("content.text._1")}</p>
            <p>{t("content.text._2")}</p>
            <p>{t("content.text._3")}</p>
            <a onClick={cookiePolicyNavigate}>{t("content.cookiePolicy-link")}</a>
            <div className={styles["allow-btn-wrapper"]}>
              <Button primary onClick={handleAllowALl}>{t("content.allowBtn")}</Button>
            </div>
            <div className={styles["toggles-wrapper"]}>
              <h4 className={styles["header"]}>{t("content.toggles.header")}</h4>
              {toggleItem(COOKIE_STRICTLY)}
              {toggleItem(COOKIE_ANALYTICS)}
              {toggleItem(COOKIE_FUNCTIONALITY)}
              {toggleItem(COOKIE_TARGETING)}
            </div>
            <ButtonGroup fullWidth>
              <Button onClick={handleRejectALl}>{t("rejectAll")}</Button>
              <Button primary onClick={handleConfirmCustomCookie}>{t("saveMy")}</Button>
            </ButtonGroup>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default Index;