import React, {createContext, useEffect, useState} from 'react';

export const STUFF_CONTEXT = createContext(null);

const Index = ({children}) => {


  useEffect(() => {
  })

  return (
    <STUFF_CONTEXT.Provider
      value={
        {
        }
      }>
      {children}
    </STUFF_CONTEXT.Provider>
  );
};

export default Index;