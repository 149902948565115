import {COOKIE_ANALYTICS, COOKIE_FUNCTIONALITY, COOKIE_STORAGE_NAME, COOKIE_TARGETING} from "../../utils/constants";
import {isLocalStorageAvailable} from "../isLocalStorageAvailable";

export const COOKIES_INITIAL = {
  [COOKIE_ANALYTICS]: false,
  [COOKIE_FUNCTIONALITY]: false,
  [COOKIE_TARGETING]: false
}

export const cookieInit = () => {
  if (typeof window === "undefined") return;
  if (!isLocalStorageAvailable()) {
    return;
  }
  let cookiesStorage = localStorage?.getItem(COOKIE_STORAGE_NAME);

  if (!cookiesStorage) {
    cookiesStorage = {...COOKIES_INITIAL};
  } else {
    cookiesStorage = {
      ...COOKIES_INITIAL,
      ...JSON.parse(cookiesStorage)
    }
  }

  localStorage.setItem(COOKIE_STORAGE_NAME, JSON.stringify(cookiesStorage));
}